.button {
  display: flex;
  justify-content: center;

  // padding: 0.938vw 1.354vw;
  padding: 1vw 1.354vw 0.738vw 1.354vw;

  gap: 0.579vw;

  border-radius: 1.563vw;

  background: #fe6728;

  border: none;

  color: #fff;
  text-align: center;
  font-family: Babe Sans;
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  line-height: 0.833vw;

  cursor: pointer;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 18px;
    padding: 24px;
    border-radius: 150px;
  }
}

.active {
  color: var(--brand-colors-orange, #fe6728);
  border-radius: 30px;
  border: 2px solid var(--snow, #fffafa);
  background: var(--snow, #fffafa);
}
