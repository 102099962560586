.header {
  position: fixed;

  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(1, 83, 81, 0.6);

  z-index: 20;

  @media screen and (max-width: 768px) {
    height: 80px;
  }

  &-container {
    height: 100%;
    width: 60.365vw;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      padding: 18px 26px;
      font-size: 18px;
      border-radius: 30px;
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 45px);

      .button {
        padding: 18px 26px;

        border-radius: 30px;

        font-size: 18px;
        line-height: 16px;
      }

      .hbtn {
        display: none;
      }
    }

    &-logo {
      width: 180px;

      cursor: pointer;

      @media screen and (max-width: 768px) {
        width: 178px;
      }
    }

    &-list {
      list-style-type: none;

      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      > li {
        > a {
          padding: 1.042vw;

          color: var(--ghost-white, #f8f8f8);
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.563vw;

          text-decoration: none;
        }
      }

      &-lang {
        display: flex;
        justify-content: center;
        align-items: center;

        gap: 0.781vw;

        @media screen and (max-width: 768px) {
          gap: 5px;
        }

        &-img {
          width: 1.528vw;

          @media screen and (max-width: 768px) {
            width: 22px;
          }
        }

        .language-modal {
          position: absolute;

          border-radius: 10px;
          background: rgba(1, 83, 81, 0.6);
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

          display: flex;
          justify-content: center;
          align-items: center;

          gap: 0.781vw;

          width: 5.556vw;
          height: 3.472vw;

          top: 4.167vw;

          @media screen and (max-width: 768px) {
            gap: 5px;

            width: 80px;
            height: 50px;

            top: 310px;
          }

          img {
            width: 1.528vw;

            cursor: pointer;

            @media screen and (max-width: 768px) {
              width: 22px;
            }
          }
        }
      }
    }

    &-menu {
      display: none;

      border-radius: 3px;
      background: #fe6728;

      padding: 5.5px;

      z-index: 1000;

      @media screen and (max-width: 768px) {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        display: block;
      }
    }
  }
}
