.modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
  }

  &-container {
    border-radius: 0.729vw;
    max-height: 80vh;
    overflow-y: auto;
    width: 35.052vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #fff;
    border-radius: 2.396vw;
    z-index: 100;
    position: relative;
    padding: 2vw 1vw 1vw 1vw;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      width: calc(100% - 20px);
      border-radius: 46px;
      padding: 2rem 2rem 1rem 2rem;
    }

    &__close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      margin: 1.563vw 2.083vw 0 0;
      align-self: flex-end;
      width: 1.25vw;
      height: 1.25vw;

      @media screen and (max-width: 768px) {
        width: 24px;
        height: 24px;
        margin: 25px 25px 0 0;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__img {
        width: 5.99vw;
        height: 5.99vw;

        @media screen and (max-width: 768px) {
          width: 95px;
          height: 95px;
        }
      }

      &__imgProject {
        border-radius: 0.729vw;
        width: 12.5vw;
        height: 12.5vw;
        border: 1px solid #fe6728;
        margin-bottom: 1.5vw;

        @media screen and (max-width: 768px) {
          width: 150px;
          height: 150px;
          margin-bottom: 15px;
        }
      }

      &__title {
        color: var(--brand-colors-green-2, #015351);
        text-align: center;
        font-family: Lato;
        font-size: 1.667vw;
        font-style: normal;
        font-weight: 900;
        line-height: 170%;
        padding: 0 1vw;
        margin: 0;

        @media screen and (max-width: 768px) {
          font-size: 24px;
          padding: 0 2rem;
        }
      }

      &__helper {
        color: var(--brand-colors-green-2, #015351);
        font-family: Lato;
        font-size: 1.042vw;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        text-align: center;

        margin: 0;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          text-align: center;
        }
      }

      &__content {
        color: var(--brand-colors-green-2, #015351);
        text-align: center;
        font-family: Lato;
        font-size: 0.833vw;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;

        a {
          color: var(--brand-colors-orange, #fe6728);
          font-family: Lato;
          font-size: 0.833vw;
          font-style: normal;
          font-weight: 400;
          line-height: 170%;
          text-decoration-line: underline;
        }

        @media screen and (max-width: 768px) {
          font-size: 14px;
          line-height: 1.5;

          a {
            font-size: 14px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
