.email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  label {
    align-self: flex-start;
    margin-top: 1.563vw;
  }

  &__button {
    width: 100%;

    margin: 1.563vw 0 1.042vw 0;
  }

  .text > a {
    cursor: pointer;
  }
}
