.nameChange {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.042vw;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  .input {
    box-sizing: border-box;
    width: 100%;

    @media screen and (max-width: 768px) {
      height: 55px;

      border-radius: 46px;
    }
  }

  .button {
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      padding: 23px;

      border-radius: 150px;
    }
  }
}