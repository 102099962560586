.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 1.042vw;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  &-label {
    color: #015351;
    font-family: Lato;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.563vw;

    @media screen and (max-width: 768px) {
      font-size: 18px;

      line-height: 30px;
    }
  }

  &-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    gap: 0.208vw;

    @media screen and (max-width: 768px) {
      gap: 10px;
    }

    &-input {
    }

    &-text {
      color: var(--brand-colors-green-2, #015351);
      leading-trim: both;
      text-edge: cap;
      font-family: Lato;
      font-size: 0.667vw;
      font-style: normal;
      font-weight: 400;
      line-height: 0px;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  &-text {
    color: var(--brand-colors-green-2, #015351);
    text-align: center;
    font-family: Lato;
    font-size: 0.938vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    > a {
      color: var(--brand-colors-orange, #fe6728);
      font-family: Lato;
      font-size: 0.938vw;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .input {
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      height: 55px;

      border-radius: 46px;
    }
  }

  .button {
    width: 100%;

    @media screen and (max-width: 768px) {
      font-size: 18px;
      padding: 23px;

      border-radius: 150px;
    }
  }
}

.modal-container-content__title {
  color: var(--brand-colors-green-2, #015351);
  text-align: center;
  font-family: Babe Sans;
  font-size: 1.875vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
}

.lg {
  height: 29.323vw;
  width: 42.813vw;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    height: 525px;
  }
}
