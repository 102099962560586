.footer {
  position: absolute;

  width: 100%;
  height: 4.167vw;

  background: var(--brand-colors-green-2, #015351);

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: auto;

    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    gap: 30px;

    #copyright {
      font-size: 18px;
      margin: 0 0 0 30px;
    }
  }

  &-element {
    color: #f8f8f8;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.563vw;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }

    &-link,
    &-link:visited {
      color: #f8f8f8;
      text-decoration: none;
      margin: 0 0.313vw;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }
    }
  }
}
