/* AppDrawer.css */
.drawer {
  position: fixed;
  // top: 0;
  top: 80px;
  right: -100%;
  width: 80%;
  height: 100%;
  background: rgba(1, 83, 81, 0.8);
  color: white;
  transition: 0.3s;
  overflow-y: auto;
  padding: 1rem;

  z-index: 100;

  // padding-top: 25px;
  padding: 25px 30px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 30px;

  > svg {
    align-self: flex-end;
  }

  // // position: relative;
  // // z-index: 1;

  // // display: flex;
  // // flex-direction: column;
  // // justify-content: center;

  // gap: 40px;

  // // max-width: 200px;

  // > p {
  //   color: var(--Ghost-White, #f8f8f8);
  //   font-family: Lato;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 30px; /* 166.667% */

  //   margin: 0;
  // }
}

.drawer.open {
  right: 0;
}

.drawer-content p {
  margin: 1rem 0;
}

.drawer-content {
  // align-items: center;
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 40px;

  max-width: 200px;

  > a {
    color: var(--Ghost-White, #f8f8f8);
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */

    text-align: left;

    margin: 0;

    text-decoration: none;
  }
}

.drawer-content p {
  // margin: 1rem 0;
}

.drawer-transparent-button {
  color: var(--brand-colors-orange, #fe6728);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Babe Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  text-transform: capitalize;

  display: inline-flex;
  padding: 18px 26px;
  justify-content: center;
  align-items: center;

  border-radius: 30px;
  border: 2px solid var(--brand-colors-orange, #fe6728);
  background-color: transparent;
  width: 100%;
}
