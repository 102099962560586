.success {
  width: 45.521vw;

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
  }

  .modal-container-content__title {
    color: var(--brand-colors-green-2, #015351);
    text-align: center;
    font-family: Babe Sans;
    font-size: 1.875vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 1.302vw;

    @media screen and (max-width: 768px) {
      font-size: 26px;

      margin-top: 25px;
    }
  }

  .modal-container-content__helper {
    color: var(--brand-colors-green-2, #015351);
    text-align: center;
    font-family: Babe Sans;
    font-size: 1.875vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 26px;
    }
  }
}
