.compensation {
  min-height: 100vh;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  background-image: url("../../img/background.png");

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 1.302vw;

  &-bg {
    background: rgba(1, 83, 81, 0.4);
    position: fixed;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }

  &-container {
    width: 42.813vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 1.302vw;

    margin-top: 10.208vw;
    margin-bottom: 12.5vw;

    z-index: 10;

    @media screen and (max-width: 1440px) {
      width: 57.083vw;
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 80px);

      margin-top: 169px;
      margin-bottom: 85px;

      gap: 30px;

      box-sizing: border-box;
    }

    &-block {
      width: 100%;

      background-color: #fff;
      border-radius: 2.662vw;

      // padding: 2.083vw 3.125vw;
      padding: 3.125vw 5.208vw 1.563vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        padding: 30px 30px 38px 30px;

        border-radius: 46px;
      }

      #second-helper {
        margin-bottom: 2.361vw;
      }

      &-icon-text {
        width: 100%;

        background-color: #fff;
        border-radius: 2.662vw;

        display: flex;
        flex-direction: column;
        overflow: hidden;

        padding: 10px;


        .icon-text-container {
          overflow: hidden;
          align-items: center;
          justify-content: center;
        }
  
        .icon {
          float: left;
          width: 25%;
          text-align: center;
          align-items: center;
          display: flex; 
          justify-content: center;
        }

        .text {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 75%;
          text-align: left;
        }

        .headerText {
          float: left;
          font-family: Babe Sans;
          font-style: normal;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          line-height: normal;
          color: var(--brand-colors-green-2, #015351);
        }
  
        .icon img {
          width: 75px;
          height: 75px;
        }

        @media screen and (max-width: 768px) {
          .icon-text-container {
            flex-direction: column;
        }

          .icon {
            margin-bottom: 8px;
            width: 100%;
        }
          .text {
            width: 100%;
          }
        }
      }

      &-header {
        text-align: center;

        color: var(--brand-colors-green-2, #015351);
        text-align: center;
        font-family: Babe Sans;
        font-size: 1.875vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin: 0;

        width: 33.958vw;

        @media screen and (max-width: 1440px) {
          width: 45.278vw;

          font-size: 2.5vw;
        }

        @media screen and (max-width: 768px) {
          font-size: 26px;

          width: 100%;
        }
      }

      &-helper {
        color: var(--brand-colors-green-2, #015351);
        font-family: Lato;
        font-size: 0.938vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.563vw;

        margin: 0.417vw 0 0 0;

        text-align: center;

        @media screen and (max-width: 1440px) {
          font-size: 1.25vw;
        }

        @media screen and (max-width: 768px) {
          font-size: 16px;

          line-height: normal;

          text-align: center;
        }
      }

      &-inputblock {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        margin-top: 1.823vw;

        gap: 1.042vw;

        &-element {
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;

          box-sizing: border-box;

          width: 100%;

          gap: 0.208vw;

          // margin-top: 20px;

          label {
            color: #015351;
            font-family: Lato;
            font-size: 0.938vw;
            font-style: normal;
            font-weight: 400;
            line-height: 1.563vw; /* 166.667% */

            @media screen and (max-width: 768px) {
              color: #015351;
              font-family: Lato;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px; /* 166.667% */
            }
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;

            gap: 11px;

            .button {
              padding: 23px;

              font-size: 18px;

              border-radius: 150px;

              width: 100%;
            }
          }

          .button {
            width: 8.125vw;

            // @media screen and (max-width: 1440px) {
            //   width:
            // }

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          .input {
            // width: 28.333vw;
            width: 100%;

            box-sizing: border-box;

            @media screen and (max-width: 1440px) {
              // width: 37.778vw;
            }

            @media screen and (max-width: 768px) {
              font-size: 18px;

              padding: 18px 20px;

              border-radius: 46px;

              width: 100%;

              box-sizing: border-box;
            }
          }
        }
      }

      &-already {
        p {
          margin-top: 1.042vw;
          font-family: Lato;
          font-size: 0.833vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          color: var(--brand-colors-green-2, #015351);

          @media screen and (max-width: 1440px) {
            font-size: 1.111vw;
          }

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
        a {
          color: var(--brand-colors-orange, #fe6728);
          font-family: Lato;
          font-size: 0.833vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;

          cursor: pointer;

          @media screen and (max-width: 1440px) {
            font-size: 1.111vw;
          }
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      &-input {
        margin-bottom: 0.365vw;

        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 0.625vw;

        box-sizing: border-box;

        @media screen and (max-width: 768px) {
        }

        .input {
          width: 95%;

          @media screen and (max-width: 768px) {
            width: 100%;

            box-sizing: border-box;

            font-size: 16px;

            border-radius: 46px;
            padding: 18px 20px;
          }
        }
      }

      &-privacy {
        align-self: flex-start;
        display: flex;
        align-items: center;

        // margin: 0.365vw 0;
        margin-top: 0.885vw;

        color: var(--brand-colors-green-2, #015351);
        font-family: Lato;
        font-size: 0.833vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.563vw;

        a,
        a:visited {
          color: var(--brand-colors-green-2, #015351);
        }

        @media screen and (max-width: 768px) {
          font-size: 12px;
          line-height: 18px;

          margin: 11px 0 19px 0;
        }

        input {
          width: 0.677vw;
          height: 0.677vw;

          @media screen and (max-width: 1440px) {
            width: 0.903vw;
            height: 0.903vw;
          }

          @media screen and (max-width: 768px) {
            width: 25px;
            height: 25px;
          }
        }
      }

      #login-button {
        .button {
          margin-top: 0.938vw;
          margin-bottom: 2.344vw;
        }
      }

      &-button {
        width: 100%;

        .button {
          width: 100%;

          text-align: center;

          color: #fff;
          text-align: center;
          font-family: Lato;
          font-size: 0.938vw;
          font-style: normal;
          font-weight: 400;
          line-height: 1.563vw; /* 166.667% */

          margin-top: 1.667vw;

          @media screen and (max-width: 768px) {
            font-size: 16px;

            padding: 24px;

            border-radius: 150px;
          }
        }
      }
    }
  }

  &-block {
    width: 42.813vw;
    height: 39.271vw;

    border-radius: 2.396vw;

    background: var(--white, #fff);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;

    z-index: 10;

    gap: 1.302vw;

    margin-top: 196px;

    padding-top: 2.604vw;

    @media screen and (max-width: 768px) {
      margin-top: 169px;

      width: calc(100% - 20px);
      height: 100%;

      border-radius: 46px;

      padding-top: 15px;
    }

    &-tooltip__img {
      width: 0.938vw;
      height: 0.938vw;

      @media screen and (max-width: 768px) {
        width: 18px;
        height: 18px;
      }
    }

    .tooltip__content {
      font-size: 12px;
      border-radius: 6px;
      background: var(--brand-colors-orange, #fe6728);
      max-width: 257px;

      z-index: 1000;

      > a {
        color: #fff;
      }
    }

    &-content {
      width: 100%;

      &-title {
        color: var(--brand-colors-green-2, #015351);
        text-align: center;
        font-family: Lato;
        font-size: 1.875vw;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 0;
        width: 36.979vw;
        margin: 2.083vw 2.917vw 1.823vw;

        @media screen and (max-width: 768px) {
          font-size: 26px;
          width: calc(100% - 72px);
          margin: 30px 36px;
          text-align: center;
        }
      }

      &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        #element5 {
          grid-column-start: 1;
          grid-column-end: 3;
          justify-content: center;
        }

        &-element {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 0.677vw;
          padding: 1.406vw 0 1.563vw 2.865vw;
          border: 0.104vw solid #f8f8f8;

          @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 20px 0 35px 0;
          }

          &__img {
            width: 1.771vw;
            height: 1.771vw;

            @media screen and (max-width: 768px) {
              width: 34px;
              height: 34px;
            }
          }

          &-block {
            display: flex;
            flex-direction: column;
            gap: 0.417vw;

            &__title {
              margin: 0;
              color: var(--brand-colors-green-2, #015351);
              text-align: left;
              font-family: Lato;
              font-size: 1.25vw;
              font-style: normal;
              font-weight: 900;
              line-height: normal;

              @media screen and (max-width: 768px) {
                font-size: 24px;
                text-align: center;
              }
            }

            &__helper {
              margin: 0;

              color: var(--brand-colors-green-2, #015351);
              font-family: Lato;
              font-size: 0.938vw;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              @media screen and (max-width: 768px) {
                font-size: 18px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    &-socials {
      width: 100%;
      height: 12.24vw;
      border-radius: 0px 0px 2.396vw 2.396vw;
      background: var(--brand-colors-orange, #fe6728);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 1.302vw;
      //margin-bottom: 4.688vw;

      @media screen and (max-width: 768px) {
        height: 242px;
        border-radius: 0px 0px 46px 46px;
        gap: 30px;
        padding-bottom: 15px;
      }

      &__title {
        color: var(--white, #fff);
        text-align: center;
        font-family: Babe Sans;
        font-size: 1.667vw;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 25.156vw;

        @media screen and (max-width: 768px) {
          font-size: 22px;
          width: 100%;
        }
      }

      &-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.26vw;

        @media screen and (max-width: 768px) {
          gap: 5px;
        }

        &__img {
          width: 2.604vw;
          height: 2.604vw;

          @media screen and (max-width: 768px) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  &-contribution {
    border-radius: 2.396vw;
    background: #fff;

    margin-top: 4.688vw;
    margin-bottom: 2.396vw;

    z-index: 10;

    padding: 2.083vw 3.125vw;

    width: 42.813vw;
    //height: 12.813vw;

    //gap: 1.302vw;

    box-sizing: border-box;
    

    @media screen and (max-width: 768px) {
      width: calc(100% - 20px);
      //height: 320px;

      padding: 30px 13px;

      border-radius: 46px;

      margin-top: 45px;
      margin-bottom: 30px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.823vw;

      @media screen and (max-width: 768px) {
        gap: 30px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.521vw;

        @media screen and (max-width: 768px) {
          gap: 8px;
        }

        &-header {
          color: var(--brand-colors-green-2, #015351);
          text-align: center;
          font-family: Babe Sans;
          font-size: 1.875vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;

          @media screen and (max-width: 768px) {
            font-size: 26px;
          }
        }

        &-text {
          color: var(--brand-colors-green-2, #015351);
          text-align: center;
          font-family: Lato;
          font-size: 0.833vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      &-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          width: 100%;
          gap: 10px;
        }
        .input {
          width: 28.333vw;

          @media screen and (max-width: 768px) {
            font-size: 18px;
            width: 100%;
            padding: 18px 20px;
            border-radius: 46px;
            box-sizing: border-box;
          }
        }

        .button {
          width: 100%;

          @media screen and (max-width: 768px) {
            font-size: 18px;
            width: 100%;
            padding: 23px 150px;
            border-radius: 150px;
          }
        }
      }

      &-privacy {
        align-self: flex-start;
        display: flex;
        align-items: center;

        margin-top: 0.885vw;

        color: var(--brand-colors-green-2, #015351);
        font-family: Lato;
        font-size: 0.833vw;
        font-style: normal;
        font-weight: 400;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          width: 95%;
          padding: 18px 20px;
          border-radius: 46px;
          box-sizing: border-box;
        }
      }
    }

    &-account {
      border-radius: 2.396vw;
      background: #fff;
      z-index: 10;
      padding: 2.083vw 3.125vw;
      width: 42.813vw;
      height: auto;
      box-sizing: border-box;
      gap: 1.302vw;
      margin-bottom: 2.083vw;
      margin-top: -5.388vw;
      
      @media screen and (max-width: 768px) {
        width: calc(100% - 20px);
        height: auto;
        padding: 30px 13px;
        border-radius: 46px;
        margin-top: 30px;
        margin-bottom: 85px;
        margin-top: -85px;
      }
  
      &-profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        z-index: 10;
  
        &-header {
          color: var(--brand-colors-green-2, #015351);
          text-align: center;
          font-family: Babe Sans;
          font-size: 1.875vw;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          
          @media screen and (max-width: 768px) {
            font-size: 26px;
          }
        }
  
        &-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 80%;
          font-family: Babe Sans;
  
          @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 80%;
            gap: 20px;
          }
  
          .button {
            width: 100%;
            font-size: 1.40625vw;
  
            @media screen and (max-width: 768px) {
              font-size: 22px;
              width: 100%;
              border-radius: 150px;
              line-height: 18px;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}

.cookie-bg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(2px);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
}

#margin {
  margin-bottom: 138px;
}

.privacy-policy {
  color: white;
}

@media screen and (min-width: 769px) {
  .__protector {
    text-overflow: ellipsis;
    width: 12vw;
    overflow: hidden;
  }
  .__protector-block {
    align-items: flex-start;
  }
}

.__protector {
  @media screen and (max-width: 768px) {
    word-break: break-all;
    padding: 0 15px;
  }
}

.__success_message {
    color: #419541;
    font-weight: bold;
}